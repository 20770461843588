<template>
    <div id="home">
        <home-video />
        <div>
            <HomepageProperty
                v-if="rental.length > 0"
                :title="$t('message.homeproperty.rentalTitle')"
                :subtitle="$t('message.homeproperty.rentalSubtitle')"
                link="/properties/rental"
                :propertyData="rental"
                />
            <Spinner v-else size="large" message="Loading Rental Properties..." line-fg-color="#000" style="padding: 5rem 0;"/>  
        </div>
        <div>
            <HomepageProperty
                v-if="sale.length > 0"
                :title="$t('message.homeproperty.saleTitle')"
                :subtitle="$t('message.homeproperty.saleSubtitle')"
                link="/properties/for-sale"
                :reversed="true"
                :propertyData="sale" />
            <Spinner v-else size="large" message="Loading Sale Properties..." line-fg-color="#000" style="padding: 5rem 0;"/>  
        </div>
        <div class="heroImage">
            <h2>{{$t('message.home.heroSubtitle')}}</h2>
            <router-link  class="btn btn_link" to="/inquire">{{$t('message.home.arrageMeeting')}}</router-link>
        </div>
        <div class="short_description" style="display:none">
            <p>{{$t(`message.home.description1`)}}</p>
            <p>{{$t(`message.home.description2`)}}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.heroImage{
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("/images/hero2.jpg");
    background-size: cover;
    margin-top: 96px;
    h2{
        font-size: 5.25rem;
        color: #fff;
        margin: 0;
    }
    .btn_link{
        color:#fff;
        padding: 12px 32px;
        border: 1px solid #fff;
        margin: 32px 0;
    }
}


.short_description{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 150px;
    padding: 64px 0;
    text-align: left;
    p{
        line-height: 1.5rem;
        width: 50%;
        letter-spacing: 1px;
    }
    p:nth-child(odd){
        padding-left: 5%;
        padding-right: 24px;
    }
    p:nth-child(even){
        padding-right: 5%;
        padding-left: 24px;
    }
}

@media (max-width: $tablet) {
    .heroImage{
        height: 50vh
    }
}

@media (max-width: $mobile) {
    .heroImage{
        h2{
            font-size: 2.75rem;
            color: #fff;
            margin: 0 20px;
            text-align: center;
        }
        .btn_link{
            padding: 12px 20px;
        }
        a{
            font-size: 0.7rem;
            padding: 12px 16px;
        }
    }
    .short_description{
        p{
            width: 100%;
            margin: 16px 32px;
        }
        p:nth-child(odd){
            padding: 0;
        }
        p:nth-child(even){
            padding: 0;
        }
    }
}
</style>

<script>
import HomeVideo from '../components/HomeVideo.vue'
import HomepageProperty from '../components/HomepageProperty'
import Spinner from 'vue-simple-spinner'

export default {
    name: "Home",
    components: {
        HomepageProperty,
        Spinner,
        HomeVideo
    },
    metaInfo: {
        title: 'Cerda Zein',
        meta: [{
            name: 'description', 
            content: "Cerda Zein"
        }]
    },
    computed:{
        rental: function(){
            const properties = this.$store.getters.properties;
            return properties.filter((property) => {
                return property.category === 'rental';
            }).slice(0,9);
        },
        sale: function(){
            const properties = this.$store.getters.properties;
            return properties.filter((property) => {
                return property.category === 'listing';
            }).slice(0,9)
        }
    },
}   
</script>
