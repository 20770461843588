<template>
    <div class="homepage_property">
        <h2>{{title}}</h2>
        <hr>
        <router-link class="btn_link btn" :to="link" :aria-label="`Go to ${title}`">
            <font-awesome-icon :icon="['fa', `search`]" class="icon"/>
            {{subtitle}}
        </router-link>
        <div class="property_content" :class="{ reversed: reversed }">
            <div class="property_data">
                <h3>{{currentProperty.Subtitle ? currentProperty.Subtitle : currentProperty.Address}}</h3>
                <div class="detail">
                <p class="">{{currentProperty.Price | priceFormat}}</p>
                <p class="middle">{{$t('message.homeproperty.beds')}}: {{currentProperty.Bedrooms}}</p>
                <p>{{$t('message.homeproperty.baths')}}: {{currentProperty.Bathrooms}}</p>
                </div>
                <router-link 
                    class="btn_link btn"
                    :aria-label="`View property ${currentProperty.Address}`"
                    :to="`/property/${currentProperty.category}s/${currentProperty.Slug}`">
                    {{$t('message.homeproperty.view')}}
                </router-link>
            </div>
            <vueper-slides
                class="property_slideshow homepage_property_slideshow"
                :arrows="true"
                :bullets="false"
                :touchable="false"
                :fixed-height="this.windowWidth > 641 ? '80vh' : '300px'"
                @slide="changeProperty"
                lazy>
                <vueper-slide
                    v-for="(property, i) in propertyData"
                    :key="i"
                    :image="property.FeaturedImage.data ? url+property.FeaturedImage.data.attributes.url : '/images/placeholder.png'">
                </vueper-slide>
            </vueper-slides>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "HomepageProperty",
    props: {
        title: String,
        subtitle: String,
        link: String,
        reversed: {
            type: Boolean,
            default: false,
        },
        propertyData: Array,
    },
    components: { VueperSlides, VueperSlide },
    methods: {
        changeProperty(event){
            this.currentProperty = this.propertyData[event.currentSlide.index];
        },
    },
    data(){
        return{
            currentProperty: this.propertyData[0],
            propertyLength: this.propertyData.length,
            url: 'https://strapi.cerda-zein.com'
        }
    }
}
</script>

<style lang="scss" scoped>
.homepage_property{
    margin-top: 5rem;
    text-align: center;
    h2{
        font-size: 2.5rem;
        text-align: center;
    }
    hr{
        width: 300px;
        opacity: 0.4;
    }
    .btn_link{
        margin: 32px 0 40px 0;
        .icon{
            margin-right: 8px;
        }
    }
}
.property_content{
    display: flex;
    align-items: center;
    .property_data{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
        padding: 40px 30px;
        margin-left: 10%;
        margin-right: 16px;
        font-size: 0.75rem;
        h3{
            font-family: $secondaryFont;
            margin: 8px 0;
            font-size: 1.5rem;
        }
        .detail{
            margin: 8px 0;
            p{
                margin: 4px 0;
                font-weight: 600;
                padding: 10px 30px;
                letter-spacing: 2px;
                text-transform: uppercase;
                &.middle{
                    border-top: 1px solid #e1e1e1;
                    border-bottom: 1px solid #e1e1e1;
                }
            } 
        }
    }
    &.reversed{
        flex-direction: row-reverse;
        .property_data{
            margin-right: 10%;
            margin-left: 16px;
        }
    }
    .property_slideshow{
        width: 65%;
    }
}

@media (max-width: $mobile) {
    .homepage_property{
        margin-top: 30px;
        text-align: center;
        h2{
            max-width: 300px;
            text-align: center;
            margin:0 auto;
        }
        hr{
            width: 300px;
            opacity: 0.4;
            margin: 32px auto;
        }
        .btn_link{
            margin: 0 0 32px 0;
            padding:10.5px 36px;
        }
    }
    .property_content{
        flex-wrap: wrap-reverse;
        .property_data{
            background: #f5f5f5;
            margin:0;
            h3{
                margin: 16px 0;
            }
            .detail{
                margin: 16px 0;
            }
            .btn_link{
                margin: 16px 0;
            }
        }
        &.reversed{
            .property_data{
                margin: 0;
            }
        }
        .property_slideshow{
            width: 100%;
        }
    }
}
</style>

<style>
.homepage_property_slideshow .vueperslides__arrow{
    opacity: 1;
}
.homepage_property_slideshow .vueperslides__arrow:hover{
    opacity: 0.7;
}
</style>